<!--
 * @Descripttion: 客户列表
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-15 16:15:07
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-11-14 10:29:13
-->
<template>
    <div class="clientList" v-loading="bloading" 
        element-loading-text="导入中，请稍等..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">客户列表</div>
        </div>
        <div class="case-wrap box-wrap">
            <div class="screen-wrap">
                <div class="search-wrap">
                    <div class="sw-item">
                        <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                        <el-button @click="clickSearch" type="primary">搜索</el-button>
                    </div>
                </div>
                <div class="ex-wrap">
                    <el-button :loading="isDowingCase" @click="caseForm" type="warning" plain>下载模板案例</el-button>
                    <el-button :loading="isDowing" @click="derivedForm" type="warning">导出</el-button>
                    <el-upload
                        ref="upload"
                        class="upload-demo"
                        action
                        :limit="1"
                        :show-file-list="false"
                        :http-request="handleSuccess"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        >
                        <el-button  type="success">导入</el-button>
                    </el-upload>
                    <div>
                        <el-button @click="isShowDrawer = true" type="primary">筛选</el-button>
                    </div>
                </div>
            </div>
            <div class="seach-wrap">
                <div class="btn">
                    <el-button @click="batchesMigration" type="primary" plain>批量迁移</el-button>
                    <el-button @click="batchesDelete" type="danger" plain>批量删除</el-button>
                </div>
                <div class="tag-wrap">
                    <div class="tw-item">
                        <div class="item" style="border: 1px solid #ddd;"></div>有效客户
                    </div>
                    <div class="tw-item">
                        <div class="item" style="background: #dddddd"></div>无效客户
                    </div>
                </div>
            </div>
            <div class="table-wrap">
                <el-table :data="tableData" border style="width: 100%" v-loading="loading" 
                    :row-class-name="tableRowClassName" height="615" @selection-change="handleSelectionChange">
                    <el-table-column align="center" type="selection" width="55"></el-table-column>
                    <el-table-column align="center" label="头像" width="80">
                        <template slot-scope="scope">
                            <el-avatar v-if="scope.row.user.head ==null">{{scope.row.name}}</el-avatar>
                            <img v-else :src="scope.row.user.head" class="pic" />
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="name" label="名称" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="mobile" label="联系方式" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="address" label="地址" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="label_type" label="跟进阶段">
                        <template slot-scope="scope">
                            <div v-if="scope.row.label_type == 1">无状态</div>
                            <div v-if="scope.row.label_type == 2">意向客户</div>
                            <div v-if="scope.row.label_type == 3">方案报价</div>
                            <div v-if="scope.row.label_type == 4">成交客户</div>
                            <div v-if="scope.row.label_type == 5">回访客户</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="quality" label="跟进频率" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <div v-if="scope.row.waiting_followup_sysfrequency">{{scope.row.waiting_followup_sysfrequency.name}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="channel_ownership" label="渠道归属"></el-table-column>
                    <el-table-column align="center" prop="user.username" label="负责人"></el-table-column>
                    <el-table-column align="center" prop="create_timex" label="创建时间" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" fixed="right" label="操作" width="90">
                        <template slot-scope="scope">
                        <div class="operation-wrap">
                            <!-- <el-tooltip class="item" effect="dark" content="详情" placement="top">
                                <i @click="clickInfo(scope.row)" class="iconfont el-icon-document"></i>
                            </el-tooltip> -->
                            <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                <i @click="clickDel(scope.row)" class="iconfont el-icon-delete"></i>
                            </el-tooltip>
                        </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination-wrap">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="10"
                        layout="total, prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-drawer
            :visible.sync="isShowDrawer"
            :direction="direction"
            :with-header="false"
            :before-close="handleCloses">
            <div class="title-wrap">
                <div class="tag"></div>
                <div class="title">筛选</div>
            </div>
            <div class="">
                <screenSelect 
                    ref="screenSelect"
                    @clickTag="clickTag"
                >
                </screenSelect>
            </div>
        </el-drawer>
        <!-- 迁移 -->
        <el-dialog :visible.sync="isAllocation" 
            :before-close="handleClose"
            custom-class="dialog-wrap"
            :close-on-click-modal="false">
            <div class="title">迁移客户</div>
            <div class="content">
                <div class="tag">迁移至：</div>
                <el-select clearable v-model="userId" placeholder="请选择负责人">
                    <el-option
                        v-for="item in userOptions"
                        :key="item.name"
                        :label="item.name"
                        :value="item.label">
                    </el-option>
                </el-select>
            </div>
            <div class="btn-wrap">
                <el-button :loading="btnLoading" @click="submitAllocation" type="primary">确定</el-button>
            </div>
        </el-dialog>
        
        <!-- 详情 -->
        <el-dialog :visible.sync="isShowClientInfo" 
            :before-close="handleClose"
            custom-class="dialog-client-wrap"
            :close-on-click-modal="false">
            <div class="title">客户详情</div>
            <customerDetails @showThread="showThread" ref="customerDetails"></customerDetails>
        </el-dialog>
        <!-- 线索 -->
        <el-dialog :visible.sync="isShowThread" 
            :before-close="handleCloseThread"
            custom-class="dialog-client-wrap"
            :close-on-click-modal="false">
            <div class="title">客户线索</div>
            <customerClues ref="customerClues"></customerClues>
        </el-dialog>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import common from "@/utils/common.js"
import axios from "axios";
import screenSelect from "./components/screenSelect.vue"
import customerDetails from "./components/customerDetails.vue"
import customerClues from "./components/customerClues.vue"
import {dealdate} from "@/utils/timeCycle";
export default {
    components:{
        screenSelect,
        customerDetails,
        customerClues
    },
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'clientList',
            keyword: '',
            filter_type: '',
            dateType: '',
            startDate: '',
            endDate: '',
            label_type: '',
            user_id: '',
            customer_frequency_id: '',
            isShowDrawer: false,
            isShowClientInfo: false,
            direction: 'rtl',
            loading: false,
            tableData: [],
            total: 0,
            currentPage: 1,
            bloading: false,
            idsList: [],
            isAllocation: false,
            userOptions: [],
            userId: '',
            btnLoading: false,
            isDowing: false,
            isShowThread: false,
            infoId: '',
            isDowingCase: false,
            is_channel: '',
            typeOptions: [{
                id: '',
                name: '全部'
            },{
                id: 2,
                name: '普通客户'
            },{
                id: 1,
                name: '渠道客户'
            }]
        }
    },
    mounted () {
        this.getCustomerList();
        this.getUserConditions();
    },
    methods: {
        // 下载案例
        caseForm(){
            let a = document.createElement("a");
            a.href = "/static/generalTemplate.xls";
            a.download = "导入普通客户模板案例.xls";
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            common.connect('/distributorpcv1/log/storeLogShopFunction',{function: 'PC-下载模板案例'})
        },
        showThread(){
            this.isShowThread = true;
            this.$nextTick(()=>{
                this.$refs.customerClues.setValue(this.infoId)
            })
        },
        // 线索
        handleCloseThread(){
            this.isShowThread = false;
        },
        // 点击详情
        clickInfo(row){
            this.infoId = row.id;
            this.isShowClientInfo = true;
            this.$nextTick(()=>{
                this.$refs.customerDetails.setValue(row)
            })
        },
        // 批量删除
        batchesDelete(){
            if(this.idsList.length <= 0){
                this.$message.warning('请选择要删除的客户')
            }else{
                this.$confirm('是否确定批量删除操作?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let list = [];
                    this.idsList.forEach(v=>{
                        list.push(v.id)
                    })
                    let params = {
                        ids: '['+list+']'
                    }
                    common.connect('/distributorpcv1/customer/delete',params,(res)=>{
                        this.getCustomerList();
                        common.connect('/distributorpcv1/log/storeLogShopFunction',{function: 'PC-批量删除客户'})
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            }
        },
        // 批量迁移
        batchesMigration(){
            if(this.idsList.length <= 0){
                this.$message.warning('请选择要迁移的客户')
            }else{
                this.isAllocation = true;
            }
        },
        // 确定迁移
        submitAllocation(){
            if(this.userId == ''){
                this.$message.error('请选择负责人');
                return;
            }
            let list = [];
            this.idsList.forEach(v=>{
                list.push(v.id)
            })
            let params = {
                ids: '['+list+']',
                user_id: this.userId
            }
            this.btnLoading = true;
            common.connect('/distributorpcv1/customer/transfer',params,(res)=>{
               if(res.isSuccess == 1){
                    this.getCustomerList();
                    common.connect('/distributorpcv1/log/storeLogShopFunction',{function: 'PC-批量迁移客户'})
               }
               this.btnLoading = false;
               this.$message.success(res.msg)
               this.isAllocation = false;
            })
        },
        handleClose(){
            this.isAllocation = false;
            this.isShowClientInfo = false;
        },
        // 多选
        handleSelectionChange(val){
            this.idsList = val;
        },
        // 获取成员列表
        getUserConditions(){
            common.connect('/distributorpcv1/user/userConditions',{},(res)=>{
                let resultSecond =  res.data.map(v =>{
                    return{
                        label: v.user_id,
                        name: v.username
                    }
                })
                this.userOptions = resultSecond;
            })
        },
        //获取客户列表
        getCustomerList(){
            let params = {
                page: this.currentPage,
                keyword: this.keyword,
                filter_type: this.filter_type,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate,
                label_type: this.label_type,
                user_id: this.user_id,
                customer_frequency_id: this.customer_frequency_id,
                customer_type_id: 1
            }
            this.loading = true;
            common.connect('/distributorpcv1/customer/index',params,(res)=>{
                if(res.isSuccess == 1){
                    this.total = res.data.count;
                    this.tableData = res.data.list;
                }
                this.loading = false;
            })
        },
        // 导出
        async derivedForm(){
            let token = localStorage.getItem("token");
            var authorization = 'Bearer ' + token
            if(this.tableData.length == 0){
                this.$message.warning('暂无数据可以导出');
                return
            }
            this.isDowing = true;
            let params = {
                keyword: this.keyword,
                filter_type: this.filter_type,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate,
                label_type: this.label_type,
                user_id: this.user_id,
                customer_frequency_id: this.customer_frequency_id,
                customer_type_id: 1,
            }
            let url = common.apiServer+'/distributorpcv1/customer/export'
            axios({
                url: url,
                responseType: "blob",
                method: 'POST',
                data: params,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authorization,
                }
            }).then(function(res) {
                let day = dealdate(new Date()).slice(0,10)
                const blob = new Blob([res.data]);
                const fileName = '普通客户数据'+day+'.xls';
                const linkNode = document.createElement('a');
                linkNode.download = fileName; //a标签的download属性规定下载文件的名称
                linkNode.style.display = 'none';
                linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
                document.body.appendChild(linkNode);
                linkNode.click();  //模拟在按钮上的一次鼠标单击
                URL.revokeObjectURL(linkNode.href); // 释放URL 对象
                document.body.removeChild(linkNode);
                common.connect('/distributorpcv1/log/storeLogShopFunction',{function: 'PC-导出客户'})
            })
            setTimeout(()=>{
                this.isDowing = false;
            },2000)
        },
        // 导入
        handleSuccess(file){
            let token = localStorage.getItem("token");
            var authorization = 'Bearer ' + token
            let formData = new FormData();
            formData.append('file',file.file);
            formData.append('type',1);
            this.bloading = true;
            let url = common.apiServer+'/distributorpcv1/customer/import'
            axios({
                url: url,
                data: formData,
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data', 
                    'Authorization': authorization,
                }
            }).then((res)=> {
                if(res.data.isSuccess == 1){
                    this.$message.success(res.data.msg);
                    common.connect('/distributorpcv1/log/storeLogShopFunction',{function: 'PC-导入客户'})
                    this.getCustomerList();
                }else{
                    this.$message.error(res.data.msg);
                }
                this.$refs.upload.clearFiles()
                this.bloading = false;
            })
        },
        // 条件筛选
        clickTag(value){
            this.filter_type = value.filter_type;
            this.dateType = value.dateType;
            this.startDate = value.startDate;
            this.endDate = value.endDate;
            this.label_type = value.label_type;
            this.user_id = value.user_id;
            this.customer_frequency_id = value.customer_frequency_id;
            this.isShowDrawer = false;
            this.getCustomerList();
        },
        // 判断
        tableRowClassName({row, rowIndex}){
            if(row.status === 3){
                return 'info-row';
            }
        },
        // 删除
        clickDel(row){
            this.$confirm('此操作将永久删除该客户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let list = [];
                list.push(row.id)
                let params = {
                    ids: '['+list+']'
                }
                common.connect('/distributorpcv1/customer/delete',params,(res)=>{
                    this.getCustomerList();
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 搜索
        clickSearch(){
            this.getCustomerList();
        },
        handleCurrentChange(val){
            this.currentPage = val;
            this.getCustomerList();
        },
        // 关闭筛选
        handleCloses(done) {
            done();
        },
    },
}
</script>

<style lang='scss'>
.clientList{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
        width: 4px;
        height: 20px;
        background: #409EFF;
        border-radius: 10px;
        }
        .title{
        font-size: 18px;
        font-weight: bold;
        padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px 20px 5px 20px;
        margin: 20px 0;
        border-radius: 6px;
    }
    .box-wrap{
        .screen-wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            .search-wrap{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .sw-item{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    .el-input{
                        width: 200px;
                        margin-right: 10px;
                    }
                }
            }
            .ex-wrap{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .upload-demo{
                    margin: 0 10px;
                }
            }
        }
        .seach-wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .btn{
                display: flex;
                flex-wrap: wrap;
            }
            .tag-wrap{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .tw-item{
                    font-size: 14px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    margin-left: 10px;
                    .item{
                        margin-right: 5px;
                        width: 12px;
                        height: 12px;
                        border-radius: 100%;
                        background: #fff;
                    }
                }
            }
        }
        .table-wrap{
            padding-top: 10px;
            .pic{
                width: 40px;
            }
            .pagination-wrap{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-top: 6px;
            }
            .operation-wrap{
                display: flex;
                justify-content: center;
                align-items: center;
                .iconfont{
                    font-size: 16px;
                    padding: 0 10px;
                    cursor: pointer;
                }
            }
        }
    }
    .el-drawer{
        width: 510px !important;
        .title-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 10px 15px;
            border-bottom: 1px solid #ddd;
            .tag{
                width: 3px;
                height: 15px;
                background: #409EFF;
                border-radius: 10px;
            }
            .title{
                font-size: 17px;
                font-weight: bold;
                padding-left: 10px;
            }
        }
    }
    // 详情
    .dialog-client-wrap{
        width: 450px;
        height: 90vh;
        overflow: hidden;
        .title{
            display: flex;
            justify-content: center;
            font-size: 17px;
            color: #000;
            font-weight: bold;
            margin-top: -10px;
        }
    }
    // 迁移
    .dialog-wrap{
        width: 500px;
        .title{
            display: flex;
            justify-content: center;
            font-size: 17px;
            color: #000;
            font-weight: bold;
            padding-top: 20px;
        }
        .content{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-left: 20px;
            padding: 30px 0;
            .tag{
                margin-right: 10px;
            }
            .el-input{
                width: 390px;
            }
        }
        .btn-wrap{
            display: flex;
            justify-content: flex-end;
            margin-right: 25px;
            padding-bottom: 10px;
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
    .el-drawer__header{
        margin-bottom: 0;
    }
    .el-table tbody tr:hover>td {
        background-color: transparent !important;
    }
    .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf  {
        background-color: transparent !important;
    }
    .el-table__fixed-right tbody tr:hover>td {
        background-color: transparent !important;
    }
}
</style>
